import { defineAsyncComponent } from 'vue'
import { defineNuxtPlugin } from '#app'

const components = {
  Accordion: defineAsyncComponent(() => import("primevue/accordion" /* webpackChunkName: "components/accordion" */).then(c => c.default || c)),
  AccordionTab: defineAsyncComponent(() => import("primevue/accordiontab" /* webpackChunkName: "components/accordion-tab" */).then(c => c.default || c)),
  AutoComplete: defineAsyncComponent(() => import("primevue/autocomplete" /* webpackChunkName: "components/auto-complete" */).then(c => c.default || c)),
  Avatar: defineAsyncComponent(() => import("primevue/avatar" /* webpackChunkName: "components/avatar" */).then(c => c.default || c)),
  AvatarGroup: defineAsyncComponent(() => import("primevue/avatargroup" /* webpackChunkName: "components/avatar-group" */).then(c => c.default || c)),
  Badge: defineAsyncComponent(() => import("primevue/badge" /* webpackChunkName: "components/badge" */).then(c => c.default || c)),
  BlockUI: defineAsyncComponent(() => import("primevue/blockui" /* webpackChunkName: "components/block-ui" */).then(c => c.default || c)),
  Breadcrumb: defineAsyncComponent(() => import("primevue/breadcrumb" /* webpackChunkName: "components/breadcrumb" */).then(c => c.default || c)),
  Button: defineAsyncComponent(() => import("primevue/button" /* webpackChunkName: "components/button" */).then(c => c.default || c)),
  Calendar: defineAsyncComponent(() => import("primevue/calendar" /* webpackChunkName: "components/calendar" */).then(c => c.default || c)),
  Card: defineAsyncComponent(() => import("primevue/card" /* webpackChunkName: "components/card" */).then(c => c.default || c)),
  Carousel: defineAsyncComponent(() => import("primevue/carousel" /* webpackChunkName: "components/carousel" */).then(c => c.default || c)),
  CascadeSelect: defineAsyncComponent(() => import("primevue/cascadeselect" /* webpackChunkName: "components/cascade-select" */).then(c => c.default || c)),
  Chart: defineAsyncComponent(() => import("primevue/chart" /* webpackChunkName: "components/chart" */).then(c => c.default || c)),
  Checkbox: defineAsyncComponent(() => import("primevue/checkbox" /* webpackChunkName: "components/checkbox" */).then(c => c.default || c)),
  Chip: defineAsyncComponent(() => import("primevue/chip" /* webpackChunkName: "components/chip" */).then(c => c.default || c)),
  Chips: defineAsyncComponent(() => import("primevue/chips" /* webpackChunkName: "components/chips" */).then(c => c.default || c)),
  ColorPicker: defineAsyncComponent(() => import("primevue/colorpicker" /* webpackChunkName: "components/color-picker" */).then(c => c.default || c)),
  Column: defineAsyncComponent(() => import("primevue/column" /* webpackChunkName: "components/column" */).then(c => c.default || c)),
  ColumnGroup: defineAsyncComponent(() => import("primevue/columngroup" /* webpackChunkName: "components/column-group" */).then(c => c.default || c)),
  ConfirmDialog: defineAsyncComponent(() => import("primevue/confirmdialog" /* webpackChunkName: "components/confirm-dialog" */).then(c => c.default || c)),
  ConfirmPopup: defineAsyncComponent(() => import("primevue/confirmpopup" /* webpackChunkName: "components/confirm-popup" */).then(c => c.default || c)),
  ContextMenu: defineAsyncComponent(() => import("primevue/contextmenu" /* webpackChunkName: "components/context-menu" */).then(c => c.default || c)),
  DataTable: defineAsyncComponent(() => import("primevue/datatable" /* webpackChunkName: "components/data-table" */).then(c => c.default || c)),
  DataView: defineAsyncComponent(() => import("primevue/dataview" /* webpackChunkName: "components/data-view" */).then(c => c.default || c)),
  DataViewLayoutOptions: defineAsyncComponent(() => import("primevue/dataviewlayoutoptions" /* webpackChunkName: "components/data-view-layout-options" */).then(c => c.default || c)),
  DeferredContent: defineAsyncComponent(() => import("primevue/deferredcontent" /* webpackChunkName: "components/deferred-content" */).then(c => c.default || c)),
  Dialog: defineAsyncComponent(() => import("primevue/dialog" /* webpackChunkName: "components/dialog" */).then(c => c.default || c)),
  Divider: defineAsyncComponent(() => import("primevue/divider" /* webpackChunkName: "components/divider" */).then(c => c.default || c)),
  Dock: defineAsyncComponent(() => import("primevue/dock" /* webpackChunkName: "components/dock" */).then(c => c.default || c)),
  Dropdown: defineAsyncComponent(() => import("primevue/dropdown" /* webpackChunkName: "components/dropdown" */).then(c => c.default || c)),
  Fieldset: defineAsyncComponent(() => import("primevue/fieldset" /* webpackChunkName: "components/fieldset" */).then(c => c.default || c)),
  FileUpload: defineAsyncComponent(() => import("primevue/fileupload" /* webpackChunkName: "components/file-upload" */).then(c => c.default || c)),
  Galleria: defineAsyncComponent(() => import("primevue/galleria" /* webpackChunkName: "components/galleria" */).then(c => c.default || c)),
  Image: defineAsyncComponent(() => import("primevue/image" /* webpackChunkName: "components/image" */).then(c => c.default || c)),
  InlineMessage: defineAsyncComponent(() => import("primevue/inlinemessage" /* webpackChunkName: "components/inline-message" */).then(c => c.default || c)),
  Inplace: defineAsyncComponent(() => import("primevue/inplace" /* webpackChunkName: "components/inplace" */).then(c => c.default || c)),
  InputMask: defineAsyncComponent(() => import("primevue/inputmask" /* webpackChunkName: "components/input-mask" */).then(c => c.default || c)),
  InputNumber: defineAsyncComponent(() => import("primevue/inputnumber" /* webpackChunkName: "components/input-number" */).then(c => c.default || c)),
  InputSwitch: defineAsyncComponent(() => import("primevue/inputswitch" /* webpackChunkName: "components/input-switch" */).then(c => c.default || c)),
  InputText: defineAsyncComponent(() => import("primevue/inputtext" /* webpackChunkName: "components/input-text" */).then(c => c.default || c)),
  Knob: defineAsyncComponent(() => import("primevue/knob" /* webpackChunkName: "components/knob" */).then(c => c.default || c)),
  Listbox: defineAsyncComponent(() => import("primevue/listbox" /* webpackChunkName: "components/listbox" */).then(c => c.default || c)),
  MegaMenu: defineAsyncComponent(() => import("primevue/megamenu" /* webpackChunkName: "components/mega-menu" */).then(c => c.default || c)),
  Menu: defineAsyncComponent(() => import("primevue/menu" /* webpackChunkName: "components/menu" */).then(c => c.default || c)),
  Menubar: defineAsyncComponent(() => import("primevue/menubar" /* webpackChunkName: "components/menubar" */).then(c => c.default || c)),
  Message: defineAsyncComponent(() => import("primevue/message" /* webpackChunkName: "components/message" */).then(c => c.default || c)),
  MultiSelect: defineAsyncComponent(() => import("primevue/multiselect" /* webpackChunkName: "components/multi-select" */).then(c => c.default || c)),
  OrderList: defineAsyncComponent(() => import("primevue/orderlist" /* webpackChunkName: "components/order-list" */).then(c => c.default || c)),
  OrganizationChart: defineAsyncComponent(() => import("primevue/organizationchart" /* webpackChunkName: "components/organization-chart" */).then(c => c.default || c)),
  OverlayPanel: defineAsyncComponent(() => import("primevue/overlaypanel" /* webpackChunkName: "components/overlay-panel" */).then(c => c.default || c)),
  Paginator: defineAsyncComponent(() => import("primevue/paginator" /* webpackChunkName: "components/paginator" */).then(c => c.default || c)),
  Panel: defineAsyncComponent(() => import("primevue/panel" /* webpackChunkName: "components/panel" */).then(c => c.default || c)),
  PanelMenu: defineAsyncComponent(() => import("primevue/panelmenu" /* webpackChunkName: "components/panel-menu" */).then(c => c.default || c)),
  Password: defineAsyncComponent(() => import("primevue/password" /* webpackChunkName: "components/password" */).then(c => c.default || c)),
  PickList: defineAsyncComponent(() => import("primevue/picklist" /* webpackChunkName: "components/pick-list" */).then(c => c.default || c)),
  ProgressBar: defineAsyncComponent(() => import("primevue/progressbar" /* webpackChunkName: "components/progress-bar" */).then(c => c.default || c)),
  ProgressSpinner: defineAsyncComponent(() => import("primevue/progressspinner" /* webpackChunkName: "components/progress-spinner" */).then(c => c.default || c)),
  RadioButton: defineAsyncComponent(() => import("primevue/radiobutton" /* webpackChunkName: "components/radio-button" */).then(c => c.default || c)),
  Rating: defineAsyncComponent(() => import("primevue/rating" /* webpackChunkName: "components/rating" */).then(c => c.default || c)),
  ScrollPanel: defineAsyncComponent(() => import("primevue/scrollpanel" /* webpackChunkName: "components/scroll-panel" */).then(c => c.default || c)),
  ScrollTop: defineAsyncComponent(() => import("primevue/scrolltop" /* webpackChunkName: "components/scroll-top" */).then(c => c.default || c)),
  SelectButton: defineAsyncComponent(() => import("primevue/selectbutton" /* webpackChunkName: "components/select-button" */).then(c => c.default || c)),
  Sidebar: defineAsyncComponent(() => import("primevue/sidebar" /* webpackChunkName: "components/sidebar" */).then(c => c.default || c)),
  Skeleton: defineAsyncComponent(() => import("primevue/skeleton" /* webpackChunkName: "components/skeleton" */).then(c => c.default || c)),
  Slider: defineAsyncComponent(() => import("primevue/slider" /* webpackChunkName: "components/slider" */).then(c => c.default || c)),
  SpeedDial: defineAsyncComponent(() => import("primevue/speeddial" /* webpackChunkName: "components/speed-dial" */).then(c => c.default || c)),
  SplitButton: defineAsyncComponent(() => import("primevue/splitbutton" /* webpackChunkName: "components/split-button" */).then(c => c.default || c)),
  Splitter: defineAsyncComponent(() => import("primevue/splitter" /* webpackChunkName: "components/splitter" */).then(c => c.default || c)),
  SplitterPanel: defineAsyncComponent(() => import("primevue/splitterpanel" /* webpackChunkName: "components/splitter-panel" */).then(c => c.default || c)),
  Steps: defineAsyncComponent(() => import("primevue/steps" /* webpackChunkName: "components/steps" */).then(c => c.default || c)),
  TabMenu: defineAsyncComponent(() => import("primevue/tabmenu" /* webpackChunkName: "components/tab-menu" */).then(c => c.default || c)),
  TabPanel: defineAsyncComponent(() => import("primevue/tabpanel" /* webpackChunkName: "components/tab-panel" */).then(c => c.default || c)),
  TabView: defineAsyncComponent(() => import("primevue/tabview" /* webpackChunkName: "components/tab-view" */).then(c => c.default || c)),
  Tag: defineAsyncComponent(() => import("primevue/tag" /* webpackChunkName: "components/tag" */).then(c => c.default || c)),
  Terminal: defineAsyncComponent(() => import("primevue/terminal" /* webpackChunkName: "components/terminal" */).then(c => c.default || c)),
  TerminalService: defineAsyncComponent(() => import("primevue/terminalservice" /* webpackChunkName: "components/terminal-service" */).then(c => c.default || c)),
  Textarea: defineAsyncComponent(() => import("primevue/textarea" /* webpackChunkName: "components/textarea" */).then(c => c.default || c)),
  TieredMenu: defineAsyncComponent(() => import("primevue/tieredmenu" /* webpackChunkName: "components/tiered-menu" */).then(c => c.default || c)),
  Timeline: defineAsyncComponent(() => import("primevue/timeline" /* webpackChunkName: "components/timeline" */).then(c => c.default || c)),
  Toast: defineAsyncComponent(() => import("primevue/toast" /* webpackChunkName: "components/toast" */).then(c => c.default || c)),
  ToggleButton: defineAsyncComponent(() => import("primevue/togglebutton" /* webpackChunkName: "components/toggle-button" */).then(c => c.default || c)),
  Toolbar: defineAsyncComponent(() => import("primevue/toolbar" /* webpackChunkName: "components/toolbar" */).then(c => c.default || c)),
  Tree: defineAsyncComponent(() => import("primevue/tree" /* webpackChunkName: "components/tree" */).then(c => c.default || c)),
  TreeSelect: defineAsyncComponent(() => import("primevue/treeselect" /* webpackChunkName: "components/tree-select" */).then(c => c.default || c)),
  TreeTable: defineAsyncComponent(() => import("primevue/treetable" /* webpackChunkName: "components/tree-table" */).then(c => c.default || c)),
  TriStateCheckbox: defineAsyncComponent(() => import("primevue/tristatecheckbox" /* webpackChunkName: "components/tri-state-checkbox" */).then(c => c.default || c)),
  VirtualScroller: defineAsyncComponent(() => import("primevue/virtualscroller" /* webpackChunkName: "components/virtual-scroller" */).then(c => c.default || c))
}

export default defineNuxtPlugin(nuxtApp => {
  for (const name in components) {
    nuxtApp.vueApp.component(name, components[name])
    nuxtApp.vueApp.component('Lazy' + name, components[name])
  }
})
