import { default as _47home_47runner_47work_47coderguard_47coderguard_47pages_47extensions_47vscode_47_91id_93_46vueMeta } from "/home/runner/work/coderguard/coderguard/pages/extensions/vscode/[id].vue?macro=true";
import { default as _47home_47runner_47work_47coderguard_47coderguard_47pages_47extensions_47vscode_47index_46vueMeta } from "/home/runner/work/coderguard/coderguard/pages/extensions/vscode/index.vue?macro=true";
import { default as _47home_47runner_47work_47coderguard_47coderguard_47pages_47feedback_47_91_46_46_46slug_93_46vueMeta } from "/home/runner/work/coderguard/coderguard/pages/feedback/[...slug].vue?macro=true";
import { default as _47home_47runner_47work_47coderguard_47coderguard_47pages_47index_46vueMeta } from "/home/runner/work/coderguard/coderguard/pages/index.vue?macro=true";
import { default as _47home_47runner_47work_47coderguard_47coderguard_47pages_47policies_47privacy_45policy_46vueMeta } from "/home/runner/work/coderguard/coderguard/pages/policies/privacy-policy.vue?macro=true";
import { default as _47home_47runner_47work_47coderguard_47coderguard_47pages_47policies_47terms_45of_45service_46vueMeta } from "/home/runner/work/coderguard/coderguard/pages/policies/terms-of-service.vue?macro=true";
export default [
  {
    name: _47home_47runner_47work_47coderguard_47coderguard_47pages_47extensions_47vscode_47_91id_93_46vueMeta?.name ?? "extensions-vscode-id",
    path: _47home_47runner_47work_47coderguard_47coderguard_47pages_47extensions_47vscode_47_91id_93_46vueMeta?.path ?? "/extensions/vscode/:id",
    file: "/home/runner/work/coderguard/coderguard/pages/extensions/vscode/[id].vue",
    children: [],
    meta: _47home_47runner_47work_47coderguard_47coderguard_47pages_47extensions_47vscode_47_91id_93_46vueMeta,
    alias: _47home_47runner_47work_47coderguard_47coderguard_47pages_47extensions_47vscode_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47runner_47work_47coderguard_47coderguard_47pages_47extensions_47vscode_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/coderguard/coderguard/pages/extensions/vscode/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47runner_47work_47coderguard_47coderguard_47pages_47extensions_47vscode_47index_46vueMeta?.name ?? "extensions-vscode",
    path: _47home_47runner_47work_47coderguard_47coderguard_47pages_47extensions_47vscode_47index_46vueMeta?.path ?? "/extensions/vscode",
    file: "/home/runner/work/coderguard/coderguard/pages/extensions/vscode/index.vue",
    children: [],
    meta: _47home_47runner_47work_47coderguard_47coderguard_47pages_47extensions_47vscode_47index_46vueMeta,
    alias: _47home_47runner_47work_47coderguard_47coderguard_47pages_47extensions_47vscode_47index_46vueMeta?.alias || [],
    redirect: _47home_47runner_47work_47coderguard_47coderguard_47pages_47extensions_47vscode_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/coderguard/coderguard/pages/extensions/vscode/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47runner_47work_47coderguard_47coderguard_47pages_47feedback_47_91_46_46_46slug_93_46vueMeta?.name ?? "feedback-slug",
    path: _47home_47runner_47work_47coderguard_47coderguard_47pages_47feedback_47_91_46_46_46slug_93_46vueMeta?.path ?? "/feedback/:slug(.*)*",
    file: "/home/runner/work/coderguard/coderguard/pages/feedback/[...slug].vue",
    children: [],
    meta: _47home_47runner_47work_47coderguard_47coderguard_47pages_47feedback_47_91_46_46_46slug_93_46vueMeta,
    alias: _47home_47runner_47work_47coderguard_47coderguard_47pages_47feedback_47_91_46_46_46slug_93_46vueMeta?.alias || [],
    redirect: _47home_47runner_47work_47coderguard_47coderguard_47pages_47feedback_47_91_46_46_46slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/coderguard/coderguard/pages/feedback/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _47home_47runner_47work_47coderguard_47coderguard_47pages_47index_46vueMeta?.name ?? "index",
    path: _47home_47runner_47work_47coderguard_47coderguard_47pages_47index_46vueMeta?.path ?? "/",
    file: "/home/runner/work/coderguard/coderguard/pages/index.vue",
    children: [],
    meta: _47home_47runner_47work_47coderguard_47coderguard_47pages_47index_46vueMeta,
    alias: _47home_47runner_47work_47coderguard_47coderguard_47pages_47index_46vueMeta?.alias || [],
    redirect: _47home_47runner_47work_47coderguard_47coderguard_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/coderguard/coderguard/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47runner_47work_47coderguard_47coderguard_47pages_47policies_47privacy_45policy_46vueMeta?.name ?? "policies-privacy-policy",
    path: _47home_47runner_47work_47coderguard_47coderguard_47pages_47policies_47privacy_45policy_46vueMeta?.path ?? "/policies/privacy-policy",
    file: "/home/runner/work/coderguard/coderguard/pages/policies/privacy-policy.vue",
    children: [],
    meta: _47home_47runner_47work_47coderguard_47coderguard_47pages_47policies_47privacy_45policy_46vueMeta,
    alias: _47home_47runner_47work_47coderguard_47coderguard_47pages_47policies_47privacy_45policy_46vueMeta?.alias || [],
    redirect: _47home_47runner_47work_47coderguard_47coderguard_47pages_47policies_47privacy_45policy_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/coderguard/coderguard/pages/policies/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: _47home_47runner_47work_47coderguard_47coderguard_47pages_47policies_47terms_45of_45service_46vueMeta?.name ?? "policies-terms-of-service",
    path: _47home_47runner_47work_47coderguard_47coderguard_47pages_47policies_47terms_45of_45service_46vueMeta?.path ?? "/policies/terms-of-service",
    file: "/home/runner/work/coderguard/coderguard/pages/policies/terms-of-service.vue",
    children: [],
    meta: _47home_47runner_47work_47coderguard_47coderguard_47pages_47policies_47terms_45of_45service_46vueMeta,
    alias: _47home_47runner_47work_47coderguard_47coderguard_47pages_47policies_47terms_45of_45service_46vueMeta?.alias || [],
    redirect: _47home_47runner_47work_47coderguard_47coderguard_47pages_47policies_47terms_45of_45service_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/coderguard/coderguard/pages/policies/terms-of-service.vue").then(m => m.default || m)
  }
]