<template>

  <Head>
    <Meta name="description"
      content="Mitigate IDE supply chain risks with Coder Guard. Prevent malicious VSCode extensions from running on your developer machine. Protect yourself against: typosquatting attacks, brandjacking, malicious takeovers, private keys and credential stealing" />

    <!-- Facebook Meta Tags -->
    <Meta property="og:type" content="website" />
    <Meta property="og:description"
      content="Mitigate IDE supply chain risks with Coder Guard. Prevent maliciou sVSCode extensions from running on your developer machine. Protect yourself against: typosquatting attacks, brandjacking, malicious takeovers, private keys and credential stealing" />
    <Meta property="og:image" content="https://coderguard.io/screenshot2.png" />

    <!-- Twitter Meta Tags -->
    <Meta name="twitter:card" content="https://coderguard.io/screenshot2.png" />
    <Meta name="twitter:title" content="Protect Your IDE from Malicious Extensions - Coder Guard" />
    <Meta name="twitter:description"
      content="Mitigate IDE supply chain risks with Coder Guard. Prevent malicious VSCode extensions from running on your developer machine. Protect yourself against: typosquatting attacks, brandjacking, malicious takeovers, private keys and credential stealing" />
    <Meta name="twitter:image" content="https://coderguard.io/screenshot2.png" />
  </Head>

  <!-- <div class="main-page-wrapper"> -->
  <!-- <Header /> -->
  <NuxtPage />
  <!-- <Footer /> -->
  <!-- </div> -->
</template>

<script setup lang="ts">
import AOS from "aos";

onMounted(() => {
  AOS.init({
    duration: 1200,
  });
});

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Coder Guard` : 'Coder Guard';
  },
  script: [
  {
      src: 'https://www.googletagmanager.com/gtag/js?id=G-75PV2S6VT8',
      async: true,
    },
    {
      src: '/gtag.js',
      async: true,
    },
  ],
})

// $appState.theme = 'lara-light-purple';
// $appState.darkTheme = false;
</script>
