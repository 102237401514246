import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/home/runner/work/coderguard/coderguard/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/home/runner/work/coderguard/coderguard/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/home/runner/work/coderguard/coderguard/node_modules/nuxt/dist/pages/runtime/router.mjs";
import _nuxt_primevue_config_mjs_nHZizNYDVK from "/home/runner/work/coderguard/coderguard/.nuxt/primevue.config.mjs";
import node_modules__64nuxtjs_color_mode_dist_runtime_plugin_client_mjs_i8AMfKeYnY from "/home/runner/work/coderguard/coderguard/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugins_primevue_ts_7rYYRZQLyx from "/home/runner/work/coderguard/coderguard/plugins/primevue.ts";
import plugins_vuetify_ts_7h9QAQEssH from "/home/runner/work/coderguard/coderguard/plugins/vuetify.ts";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  _nuxt_primevue_config_mjs_nHZizNYDVK,
  node_modules__64nuxtjs_color_mode_dist_runtime_plugin_client_mjs_i8AMfKeYnY,
  plugins_primevue_ts_7rYYRZQLyx,
  plugins_vuetify_ts_7h9QAQEssH
]